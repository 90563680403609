import { defineComponent as _defineComponent } from 'vue'
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";

import { DUMMY_CARD_IMAGE_URL } from "@ems/constants";

import DialogModule from "@/store/UI/Dialog";
import DialogPurchaseRECs from "@ems/containers/GreenOptions/TheDialog/PurchaseRECs.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PurChaseRECs.Form',
  emits: ["handleSuccess"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;

const handleClickGuide = () => {
  DialogModule.showModal({ component: DialogPurchaseRECs, emits });
};

const __returned__ = { emits, handleClickGuide, BaseSvgIcon, get DUMMY_CARD_IMAGE_URL() { return DUMMY_CARD_IMAGE_URL }, get DialogModule() { return DialogModule }, DialogPurchaseRECs }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})