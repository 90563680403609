import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.DialogModule.isToggle,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.DialogModule.isToggle) = $event)),
    center: "",
    "before-close": $setup.handleClose,
    "show-close": false
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", { class: "flex justify-end items-center space-x-4" }, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--red-primary",
          onClick: $setup.handleClose
        }, " Ok ")
      ])
    ]),
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "2xl:text-lg font-semibold" }, "Purchase is successful.", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-gray-400" }, [
        _createTextVNode(" Balance in eWallet: "),
        _createElementVNode("b", { class: "text-white" }, "$736.00")
      ], -1))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}