import { defineComponent as _defineComponent } from 'vue'
import DialogModule from "@/store/UI/Dialog";
import DialogPurchaseRECsSuccessful from "@ems/containers/GreenOptions/TheDialog/PurchaseRECsSuccessful.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PurchaseRECs',
  setup(__props, { expose: __expose }) {
  __expose();

const handleClose = () => {
  DialogModule.hideModal();
};

const handleConfirm = async () => {
  const { emits } = DialogModule.getRest as any;

  DialogModule.hideModal();
  DialogModule.showModal({ component: DialogPurchaseRECsSuccessful, emits });
};

const __returned__ = { handleClose, handleConfirm, get DialogModule() { return DialogModule }, DialogPurchaseRECsSuccessful }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})