import { defineComponent as _defineComponent } from 'vue'
import DialogModule from "@/store/UI/Dialog";


export default /*@__PURE__*/_defineComponent({
  __name: 'PurchaseRECsSuccessful',
  setup(__props, { expose: __expose }) {
  __expose();

const handleClose = () => {
  const { emits } = DialogModule.getRest as any;

  emits("handleSuccess", true);

  DialogModule.hideModal();
};

const __returned__ = { handleClose, get DialogModule() { return DialogModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})