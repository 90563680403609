import { defineComponent as _defineComponent } from 'vue'
import { ref } from "vue";
import PurChaseRECsForm from "@ems/containers/GreenOptions/TheForm/PurChaseRECs.Form.vue";
import ColumnChart from "@/components/AnyChart/ColumnChart.vue";

import BaseSvgImage from "@/components/BaseSvgImage.vue";

import {
  dataScope_2,
  dataRenewables_2,
  dataTarget_2,
  dataMarker,
} from "../../../../../data/Scope_2";


export default /*@__PURE__*/_defineComponent({
  __name: 'BuyRECs',
  setup(__props, { expose: __expose }) {
  __expose();

const step = ref(2);
const isSuccess = ref(false);

const handleSuccess = (value: boolean) => (isSuccess.value = value);

const __returned__ = { step, isSuccess, handleSuccess, ref, PurChaseRECsForm, ColumnChart, BaseSvgImage, get dataScope_2() { return dataScope_2 }, get dataRenewables_2() { return dataRenewables_2 }, get dataTarget_2() { return dataTarget_2 }, get dataMarker() { return dataMarker } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})