import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box p-8" }
const _hoisted_2 = { class: "grid grid-cols-2 2xl:gap-5 gap-3 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 my-3 2xl:my-5" }
const _hoisted_3 = { style: { height: '210px' } }
const _hoisted_4 = { class: "px-5 pt-2 pb-4 space-y-2" }
const _hoisted_5 = { class: "flex flex-wrap justify-between" }
const _hoisted_6 = { class: "leading-6" }
const _hoisted_7 = { class: "inline-block" }
const _hoisted_8 = { class: "leading-6" }
const _hoisted_9 = { class: "inline-block" }
const _hoisted_10 = { style: { height: '210px' } }
const _hoisted_11 = { class: "px-5 pt-2 pb-4 space-y-2" }
const _hoisted_12 = { class: "flex flex-wrap justify-between" }
const _hoisted_13 = { class: "leading-6" }
const _hoisted_14 = { class: "inline-block" }
const _hoisted_15 = { class: "leading-6" }
const _hoisted_16 = { class: "inline-block" }
const _hoisted_17 = { style: { height: '210px' } }
const _hoisted_18 = { class: "px-5 pt-2 pb-4 space-y-2" }
const _hoisted_19 = { class: "flex flex-wrap justify-between" }
const _hoisted_20 = { class: "leading-6" }
const _hoisted_21 = { class: "inline-block" }
const _hoisted_22 = { class: "leading-6" }
const _hoisted_23 = { class: "inline-block" }
const _hoisted_24 = { style: { height: '210px' } }
const _hoisted_25 = { class: "px-5 pt-2 pb-4 space-y-2" }
const _hoisted_26 = { class: "flex flex-wrap justify-between" }
const _hoisted_27 = { class: "leading-6" }
const _hoisted_28 = { class: "inline-block" }
const _hoisted_29 = { class: "leading-6" }
const _hoisted_30 = { class: "inline-block" }
const _hoisted_31 = { class: "flex flex-col justify-end items-end space-y-5 mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[21] || (_cache[21] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold" }, " Below are available RECs to fulfil your selected target: ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.styles.card)
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "w-full h-full object-fill object-center bg-cover",
            style: _normalizeStyle({
              backgroundImage: `url('${$setup.DUMMY_CARD_IMAGE_URL}')`,
              backgroundBlendMode: 'multiply',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            })
          }, null, 4)
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "absolute rounded-r-full top-1/3 left-0 w-24 h-14 bg-yellow-400 text-white text-2xl font-bold flex justify-center items-center" }, [
          _createElementVNode("span", null, "100$")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "font-bold 2xl:text-lg mt-2" }, "RECs Name", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, [
              _createElementVNode("i", _hoisted_7, [
                _createVNode($setup["BaseSvgIcon"], {
                  class: "inline-block text-white w-5 h-5",
                  name: "location"
                })
              ]),
              _cache[0] || (_cache[0] = _createTextVNode(" Singapore "))
            ]),
            _createElementVNode("p", _hoisted_8, [
              _createElementVNode("i", _hoisted_9, [
                _createVNode($setup["BaseSvgIcon"], {
                  class: "inline-block text-white w-5 h-5",
                  name: "calendar"
                })
              ]),
              _cache[1] || (_cache[1] = _createTextVNode(" 1st March 2021 "))
            ])
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex flex-wrap justify-between items-center mt-5" }, [
            _createElementVNode("p", { class: "2xl:text-lg leading-6 mr-10" }, [
              _createElementVNode("b", null, "1.000 MWh")
            ])
          ], -1))
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.styles.card)
      }, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            class: "w-full h-full object-fill object-center bg-cover",
            style: _normalizeStyle({
              backgroundImage: `url('${$setup.DUMMY_CARD_IMAGE_URL}')`,
              backgroundBlendMode: 'multiply',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            })
          }, null, 4)
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "absolute rounded-r-full top-1/3 left-0 w-24 h-14 bg-yellow-400 text-white text-2xl font-bold flex justify-center items-center" }, [
          _createElementVNode("span", null, "100$")
        ], -1)),
        _createElementVNode("div", _hoisted_11, [
          _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "font-bold 2xl:text-lg mt-2" }, "RECs Name", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, [
              _createElementVNode("i", _hoisted_14, [
                _createVNode($setup["BaseSvgIcon"], {
                  class: "inline-block text-white w-5 h-5",
                  name: "location"
                })
              ]),
              _cache[5] || (_cache[5] = _createTextVNode(" Singapore "))
            ]),
            _createElementVNode("p", _hoisted_15, [
              _createElementVNode("i", _hoisted_16, [
                _createVNode($setup["BaseSvgIcon"], {
                  class: "inline-block text-white w-5 h-5",
                  name: "calendar"
                })
              ]),
              _cache[6] || (_cache[6] = _createTextVNode(" 1st March 2021 "))
            ])
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex flex-wrap justify-between items-center mt-5" }, [
            _createElementVNode("p", { class: "2xl:text-lg leading-6 mr-10" }, [
              _createElementVNode("b", null, "1.000 MWh")
            ])
          ], -1))
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.styles.card)
      }, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", {
            class: "w-full h-full object-fill object-center bg-cover",
            style: _normalizeStyle({
              backgroundImage: `url('${$setup.DUMMY_CARD_IMAGE_URL}')`,
              backgroundBlendMode: 'multiply',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            })
          }, null, 4)
        ]),
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "absolute rounded-r-full top-1/3 left-0 w-24 h-14 bg-yellow-400 text-white text-2xl font-bold flex justify-center items-center" }, [
          _createElementVNode("span", null, "100$")
        ], -1)),
        _createElementVNode("div", _hoisted_18, [
          _cache[12] || (_cache[12] = _createElementVNode("h1", { class: "font-bold 2xl:text-lg mt-2" }, "RECs Name", -1)),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("p", _hoisted_20, [
              _createElementVNode("i", _hoisted_21, [
                _createVNode($setup["BaseSvgIcon"], {
                  class: "inline-block text-white w-5 h-5",
                  name: "location"
                })
              ]),
              _cache[10] || (_cache[10] = _createTextVNode(" Singapore "))
            ]),
            _createElementVNode("p", _hoisted_22, [
              _createElementVNode("i", _hoisted_23, [
                _createVNode($setup["BaseSvgIcon"], {
                  class: "inline-block text-white w-5 h-5",
                  name: "calendar"
                })
              ]),
              _cache[11] || (_cache[11] = _createTextVNode(" 1st March 2021 "))
            ])
          ]),
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex flex-wrap justify-between items-center mt-5" }, [
            _createElementVNode("p", { class: "2xl:text-lg leading-6 mr-10" }, [
              _createElementVNode("b", null, "1.000 MWh")
            ])
          ], -1))
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.styles.card)
      }, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", {
            class: "w-full h-full object-fill object-center bg-cover",
            style: _normalizeStyle({
              backgroundImage: `url('${$setup.DUMMY_CARD_IMAGE_URL}')`,
              backgroundBlendMode: 'multiply',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            })
          }, null, 4)
        ]),
        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "absolute rounded-r-full top-1/3 left-0 w-24 h-14 bg-yellow-400 text-white text-2xl font-bold flex justify-center items-center" }, [
          _createElementVNode("span", null, "100$")
        ], -1)),
        _createElementVNode("div", _hoisted_25, [
          _cache[17] || (_cache[17] = _createElementVNode("h1", { class: "font-bold 2xl:text-lg mt-2" }, "RECs Name", -1)),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("p", _hoisted_27, [
              _createElementVNode("i", _hoisted_28, [
                _createVNode($setup["BaseSvgIcon"], {
                  class: "inline-block text-white w-5 h-5",
                  name: "location"
                })
              ]),
              _cache[15] || (_cache[15] = _createTextVNode(" Singapore "))
            ]),
            _createElementVNode("p", _hoisted_29, [
              _createElementVNode("i", _hoisted_30, [
                _createVNode($setup["BaseSvgIcon"], {
                  class: "inline-block text-white w-5 h-5",
                  name: "calendar"
                })
              ]),
              _cache[16] || (_cache[16] = _createTextVNode(" 1st March 2021 "))
            ])
          ]),
          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "flex flex-wrap justify-between items-center mt-5" }, [
            _createElementVNode("p", { class: "2xl:text-lg leading-6 mr-10" }, [
              _createElementVNode("b", null, "1.000 MWh")
            ])
          ], -1))
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_31, [
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--red-primary",
          onClick: _withModifiers($setup.handleClickGuide, ["prevent"])
        }, " Purchase and Retire ")
      ]),
      _cache[20] || (_cache[20] = _createElementVNode("p", null, "(You will be taken to a confirmation page before payment is made)", -1))
    ])
  ]))
}